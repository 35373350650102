import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ImpostoProvider} from 'src/providers/nfe/imposto';
import {UnitProvider} from 'src/providers/produto/unidade';
import {ErrorUtil} from 'src/util/error';
import {Snackbar} from 'src/util/snackbar';
import Swal from 'sweetalert2';
import {CheckPermission} from 'src/service/checkPermission';

@Component({
  selector: 'app-cadastro-impostos',
  templateUrl: './cadastro-impostos.component.html',
  styleUrls: ['./cadastro-impostos.component.scss']
})
export class CadastroImpostosComponent implements OnInit {

  @Input() enableClearOnEscapePressed = true;

  impostoForm: FormGroup;
  impostoExistente: any;
  enviado: boolean = false;
  enviando: boolean = false;
  editar: boolean = false;
  public isShared: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    public snackbar: Snackbar,
    public erroUtil: ErrorUtil,
    public impostoProvider: ImpostoProvider,
    public checkPermission: CheckPermission
  ) {
  }

  ngOnInit() {
    try {
      this.impostoForm = this.fb.group({
        description: [null, Validators.required],
        SituacaoTributaria: [null, Validators.required],
        Aliquota: [null, Validators.required],
        cfop: [null, Validators.required],
        AliquotaDiferimento: [null, Validators.required],
        AliquotaDiferimentoFCP: [null, Validators.required],
        SituacaoTributariaPis: [null, Validators.required],
        AliquotaPercentualPis: [null, Validators.required],
        SituacaoTributariaCofins: [null, Validators.required],
        AliquotaPercentualCofins: [null, Validators.required],
        AliquotaReducao: [null, Validators.required],
        aliquota_reducao_st: [null],
        aliquota_mva: [null],
        industria: [null],
        BeneficioFiscal: [null, Validators.required],
        bc_st_retido: [null],
        aliquota_st_retido: [null],
        valor_st_retido: [null],
        bc_st_dest: [null],
        valor_st_dest: [null],
        valor_fcp_retido: [null],
        aliquota_fcp_retido: [null],
        aliquota_bc_efetivo: [null],
        aliquota_icms_efetivo: [null],
        SituacaoTributariaIpi: ['53', Validators.required],
        codigo_enquadramento: ['999', Validators.required],
        AliquotaIpi: [0.00, Validators.required],
        aliquota_credito: [null],
        aliquota_st: [null],
        aliquota_fcp_st: [null],
        valor_icms_substituto: [null],
        motivo_desoneracao_st: [null],
      });
    } catch (error) {
      console.error(error);
    }
    if (this.editar) {
      this.fillImpostoForm();
    }
  }

  fillImpostoForm() {
    const controls = this.impostoForm.controls;
    const icms = this.impostoExistente.icms;
    const pis = this.impostoExistente.pis;
    const cofins = this.impostoExistente.cofins;
    const ipi = this.impostoExistente.ipi;

    this.setControlsBySitTrib(icms.situacao_tributaria);

    controls.description.setValue(this.impostoExistente.description);
    controls.SituacaoTributaria.setValue(icms.situacao_tributaria);
    controls.Aliquota.setValue(icms.aliquota);
    controls.cfop.setValue(icms.codigo_cfop);
    controls.AliquotaDiferimento.setValue(icms.aliquota_diferimento);
    controls.AliquotaDiferimentoFCP.setValue(icms.aliquota_diferimento_fcp);
    controls.SituacaoTributariaPis.setValue(pis.situacao_tributaria);
    controls.AliquotaPercentualPis.setValue(pis.aliquota);
    controls.SituacaoTributariaCofins.setValue(cofins.situacao_tributaria);
    controls.AliquotaPercentualCofins.setValue(cofins.aliquota);
    controls.AliquotaReducao.setValue(icms.aliquota_reducao);
    controls.BeneficioFiscal.setValue(icms.beneficio_fiscal);
    controls.bc_st_retido.setValue(icms.bc_st_retido);
    controls.aliquota_st_retido.setValue(icms.aliquota_st_retido);
    controls.valor_st_retido.setValue(icms.valor_st_retido);
    controls.bc_st_dest.setValue(icms.bc_st_dest);
    controls.valor_st_dest.setValue(icms.valor_st_dest);
    controls.valor_fcp_retido.setValue(icms.valor_fcp_retido);
    controls.aliquota_fcp_retido.setValue(icms.aliquota_fcp_retido);
    controls.aliquota_bc_efetivo.setValue(icms.aliquota_bc_efetivo);
    controls.aliquota_icms_efetivo.setValue(icms.aliquota_icms_efetivo);
    controls.aliquota_reducao_st.setValue(icms.aliquota_reducao_st);
    controls.industria.setValue(icms.industria);
    controls.aliquota_mva.setValue(icms.aliquota_mva);
    controls.SituacaoTributariaIpi.setValue(ipi.situacao_tributaria);
    controls.codigo_enquadramento.setValue(ipi.codigo_enquadramento);
    controls.AliquotaIpi.setValue(ipi.aliquota);
    controls.aliquota_credito.setValue(icms.aliquota_credito);
    controls.aliquota_st.setValue(icms.aliquota_st);
    controls.aliquota_fcp_st.setValue(icms.aliquota_fcp_st);
    controls.valor_icms_substituto.setValue(icms.valor_icms_substituto);
    controls.motivo_desoneracao_st.setValue(icms.motivo_desoneracao_st);
    this.isShared = this.impostoExistente.isShared;
  }

  setControlsBySitTrib(sitTrib) {
    const controls = this.impostoForm.controls;

    const fieldsToDisable = [
      'Aliquota', 'AliquotaDiferimento', 'AliquotaDiferimentoFCP',
      'AliquotaReducao', 'BeneficioFiscal', 'bc_st_retido',
      'aliquota_st_retido', 'valor_st_retido', 'bc_st_dest',
      'valor_st_dest', 'valor_fcp_retido', 'aliquota_fcp_retido',
      'aliquota_bc_efetivo', 'aliquota_icms_efetivo', 'aliquota_reducao_st',
      'aliquota_mva', 'industria', 'aliquota_credito', 'aliquota_st',
      'aliquota_fcp_st', 'valor_icms_substituto', 'motivo_desoneracao_st'
    ];
    fieldsToDisable.forEach(field => controls[field].disable());

    const fieldsToEnable = {
      '00': ['Aliquota'],
      '10': ['aliquota_st', 'aliquota_fcp_st', 'aliquota_mva', 'aliquota_reducao_st', 'motivo_desoneracao_st'],
      '20': ['AliquotaReducao', 'BeneficioFiscal'],
      '30': ['aliquota_st', 'aliquota_fcp_st', 'aliquota_mva', 'aliquota_reducao_st', 'motivo_desoneracao_st'],
      '40': ['BeneficioFiscal'],
      '41': ['BeneficioFiscal'],
      '50': ['BeneficioFiscal'],
      '51': ['AliquotaDiferimento', 'AliquotaDiferimentoFCP', 'BeneficioFiscal'],
      '60': [
        'bc_st_retido', 'aliquota_st_retido', 'valor_st_retido',
        'bc_st_dest', 'valor_st_dest', 'valor_fcp_retido',
        'aliquota_fcp_retido', 'aliquota_bc_efetivo', 'aliquota_icms_efetivo'
      ],
      '90': ['AliquotaReducao', 'aliquota_reducao_st', 'aliquota_mva', 'industria'],
      '101': ['aliquota_credito'],
      '201': [
        'aliquota_credito', 'aliquota_st', 'aliquota_fcp_st',
        'aliquota_mva', 'AliquotaReducao', 'aliquota_reducao_st'
      ],
      '202': [
        'aliquota_st', 'aliquota_fcp_st', 'aliquota_mva',
        'AliquotaReducao', 'aliquota_reducao_st'
      ],
      '203': [
        'aliquota_st', 'aliquota_fcp_st', 'aliquota_mva',
        'AliquotaReducao', 'aliquota_reducao_st'
      ],
      '500': [
        'bc_st_retido', 'aliquota_st_retido', 'valor_st_retido',
        'valor_icms_substituto', 'valor_fcp_retido', 'aliquota_fcp_retido',
        'aliquota_bc_efetivo', 'aliquota_icms_efetivo'
      ]
    };

    if (fieldsToEnable[sitTrib]) {
      fieldsToEnable[sitTrib].forEach(field => controls[field].enable());
      if (sitTrib === '20' || sitTrib === '51') {
        controls.BeneficioFiscal.clearValidators();
      }
    }
  }

  setUnnecessaryFieldsToNull() {
    const controls = this.impostoForm;

    const fieldsToEnable = {
      '00': ['Aliquota'],
      '10': ['aliquota_st', 'aliquota_fcp_st', 'aliquota_mva', 'aliquota_mva', 'aliquota_reducao_st', 'motivo_desoneracao_st'],
      '20': ['AliquotaReducao', 'BeneficioFiscal'],
      '30': ['aliquota_st', 'aliquota_fcp_st', 'aliquota_mva', 'aliquota_mva', 'aliquota_reducao_st', 'motivo_desoneracao_st'],
      '40': ['BeneficioFiscal'],
      '41': ['BeneficioFiscal'],
      '50': ['BeneficioFiscal'],
      '51': ['AliquotaDiferimento', 'AliquotaDiferimentoFCP', 'BeneficioFiscal'],
      '60': [
        'bc_st_retido', 'aliquota_st_retido', 'valor_st_retido',
        'bc_st_dest', 'valor_st_dest', 'valor_fcp_retido',
        'aliquota_fcp_retido', 'aliquota_bc_efetivo', 'aliquota_icms_efetivo'
      ],
      '90': ['AliquotaReducao', 'aliquota_reducao_st', 'aliquota_mva', 'industria'],
      '101': ['aliquota_credito'],
      '201': [
        'aliquota_credito', 'aliquota_st', 'aliquota_fcp_st',
        'aliquota_mva', 'AliquotaReducao', 'aliquota_reducao_st'
      ],
      '202': [
        'aliquota_st', 'aliquota_fcp_st', 'aliquota_mva',
        'AliquotaReducao', 'aliquota_reducao_st'
      ],
      '203': [
        'aliquota_st', 'aliquota_fcp_st', 'aliquota_mva',
        'AliquotaReducao', 'aliquota_reducao_st'
      ],
      '500': [
        'bc_st_retido', 'aliquota_st_retido', 'valor_st_retido',
        'valor_icms_substituto', 'valor_fcp_retido', 'aliquota_fcp_retido',
        'aliquota_bc_efetivo', 'aliquota_icms_efetivo'
      ]
    };
    const allEnabledFields = new Set<string>();
    Object.values(fieldsToEnable).forEach(fields => {
      fields.forEach(field => allEnabledFields.add(field));
    });

    const fieldsToKeep = new Set(fieldsToEnable[this.impostoForm.get('SituacaoTributaria').value] || []);
    Object.keys(controls.controls).forEach(field => {
      if (!fieldsToKeep.has(field) && allEnabledFields.has(field)) {
        controls.get(field).setValue(null);
      }
    });
  }

  setImpostoObj() {
    this.setUnnecessaryFieldsToNull();
    const getValue = (field) => this.impostoForm.get(field).value;
    const obj = {
      description: getValue('description'),
      icms: {
        codigo_cfop: getValue('cfop'),
        situacao_tributaria: getValue('SituacaoTributaria'),
        aliquota: getValue('Aliquota'),
        aliquota_diferimento: getValue('AliquotaDiferimento'),
        aliquota_diferimento_fcp: getValue('AliquotaDiferimentoFCP'),
        aliquota_reducao: getValue('AliquotaReducao'),
        beneficio_fiscal: getValue('BeneficioFiscal'),
        bc_st_retido: getValue('bc_st_retido'),
        aliquota_st_retido: getValue('aliquota_st_retido'),
        valor_st_retido: getValue('valor_st_retido'),
        bc_st_dest: getValue('bc_st_dest'),
        valor_st_dest: getValue('valor_st_dest'),
        valor_fcp_retido: getValue('valor_fcp_retido'),
        aliquota_fcp_retido: getValue('aliquota_fcp_retido'),
        aliquota_bc_efetivo: getValue('aliquota_bc_efetivo'),
        aliquota_icms_efetivo: getValue('aliquota_icms_efetivo'),
        aliquota_reducao_st: getValue('aliquota_reducao_st'),
        aliquota_mva: getValue('aliquota_mva'),
        industria: getValue('industria'),
        aliquota_credito: getValue('aliquota_credito'),
        aliquota_st: getValue('aliquota_st'),
        aliquota_fcp_st: getValue('aliquota_fcp_st'),
        valor_icms_substituto: getValue('valor_icms_substituto'),
        motivo_desoneracao_st: getValue('motivo_desoneracao_st'),
      },
      pis: {
        situacao_tributaria: getValue('SituacaoTributariaPis'),
        aliquota: getValue('AliquotaPercentualPis'),
      },
      cofins: {
        situacao_tributaria: getValue('SituacaoTributariaCofins'),
        aliquota: getValue('AliquotaPercentualCofins'),
      },
      ipi: {
        situacao_tributaria: getValue('SituacaoTributariaIpi'),
        codigo_enquadramento: getValue('codigo_enquadramento'),
        aliquota: getValue('AliquotaIpi'),
      },
      isShared: this.isShared
    };
    if (this.editar) {
      obj['id'] = this.impostoExistente.id;
    }
    return obj;
  }

  newImposto() {
    this.enviado = true;
    if (this.impostoForm.invalid) {
      return;
    }
    this.enviando = true;
    this.impostoProvider.postImposto(this.setImpostoObj()).then((result) => {
      this.activeModal.close('success');
      this.enviando = false;
    }).catch((err) => {
      this.enviando = false;
      Swal.fire({
        title: 'Falha!',
        text: err.error.message,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
    });
  }

  editImposto() {
    this.enviado = true;

    if (this.impostoForm.invalid) {
      return;
    }
    this.enviando = true;
    this.impostoProvider.putImposto(this.setImpostoObj()).then((result) => {
      this.activeModal.close('success');
      this.enviando = false;
    }).catch((err) => {
      this.enviando = false;
      Swal.fire({
        title: 'Falha!',
        text: err.error.message,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
    });
  }

  checkIfBeneficioFiscal() {
    let csts = ['20', '40', '41', '50', '51', '90'];
    return csts.includes(this.impostoForm.controls.SituacaoTributaria.value);
  }

}
